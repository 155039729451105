<template>
  <NuxtLoadingIndicator />
  <NuxtLayout>
    <NuxtPage />
    <UNotifications>
      <template #title="{ title }">
        <span v-html="title" />
      </template>
      <template #description="{ description }">
        <span v-html="description" />
      </template>
    </UNotifications>
    <UModals />
  </NuxtLayout>
</template>

<script setup>

const colorMode = useColorMode()
colorMode.preference = 'light';

const { locale } = useI18n()
console.log('locale.value at app.vue', locale.value)

const htmlAttrs = computed(() => {
  return {
    lang: locale.value,
  }
})

useHead({
  htmlAttrs: htmlAttrs,
  meta: [
    { charset: "utf-8" },
    { name: "viewport", content: "width=device-width, initial-scale=1, maximum-scale=1, viewport-fit=cover" },
    {
      name: "title",
      content:
        "NomadRx | Overseas healthcare for expats",
    },
    {
      name: "description",
      content:
        "Find overseas doctors that accept your insurance",
    },
    { property: "og:type", content: "website" },
    {
      property: "og:title",
      content: "NomadRx | Overseas healthcare for expats",
    },
    {
      property: "og:description",
      content:
        "Find overseas doctors that accept your insurance.",
    },
    {
      property: "og:image",
      content: "https://www.nomadrx.co/nomadrx_og.png",
    },
    {
      property: "og:url",
      content: "https://www.nomadrx.co",
    },
    {
      name: "facebook-domain-verification",
      content: "t0bh2oq8gjg4ji9x16cjjfenke40pj",
    },
  ],
  link: [
    {
      rel: "canonical",
      href: "https://www.nomadrx.co/",
    },
  ]
});

</script>
